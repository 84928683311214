<template>
  <div class="main-container">
    <h1 class="main-heading">{{ $t("TROPHY") }}</h1>
    <div class="header-container">
      <input type="file" ref="file" accept=".csv" @change="onFileChanged" />
    </div>
    <div class="content-container">
      <div class="table">
        <div class="header-row">
          <div class="cell header-cell">
            {{ $t("TROPHY_VALUES.MILESTONE") }}
          </div>
          <div class="cell header-cell">REWARD 1</div>
          <div class="cell header-cell">REWARD 2</div>
        </div>
        <div
          class="table-row"
          v-for="(milestone, index) in Object.keys(trophyObj).sort(
            (a, b) => parseInt(a) - parseInt(b)
          )"
          v-bind:key="milestone"
          :class="index % 2 === 1 ? 'alternate' : ''"
        >
          <div class="cell">{{ milestone }}</div>
          <div class="cell">
            <select
              v-model.number="trophyObj[milestone][0].type"
              @change="() => updateRewardType(milestone, 0)"
            >
              <option
                v-for="type in trophyConstants.TROPHY_REWARD_TYPES"
                v-bind:key="type.value"
                :value="type.value"
              >
                {{ $t("TROPHY_VALUES." + type.text) }}
              </option>
            </select>
          </div>
          <div class="cell reward-cell">
            <template v-if="[0, 1].includes(trophyObj[milestone][0].type)">
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.AMOUNT") }}</p>
                <input v-model.number="trophyObj[milestone][0].amount" />
              </div>
            </template>
            <template v-if="trophyObj[milestone][0].type === 2">
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.CHEST") }}</p>
                <select v-model="trophyObj[milestone][0].id">
                  <option
                    v-for="option in chests"
                    :value="option.id"
                    v-bind:key="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.ARENA") }}</p>
                <input v-model.number="trophyObj[milestone][0].arena" />
              </div>
            </template>
            <template v-if="[3, 4].includes(trophyObj[milestone][0].type)">
              <div class="subcell">
                <p>{{ "Selection Type" }}</p>
                <select v-model.number="trophyObj[milestone][0].selection_type">
                  <option
                    v-for="option in trophyConstants.CARD_REWARD_TYPE"
                    :value="option.value"
                    v-bind:key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div v-if="trophyObj[milestone][0].selection_type !== 1" class="subcell">
                <p>{{ $t("TROPHY_VALUES.RARITY") }}</p>
                <select v-model.number="trophyObj[milestone][0].rarity">
                  <option
                    v-for="option in trophyConstants.RARITIES"
                    :value="option.value"
                    v-bind:key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div v-else class="subcell">
                <p>{{ "Card" }}</p>
                <select v-model="trophyObj[milestone][0].card_id">
                  <option
                    v-for="card in trophyObj[milestone][0].type === 3
                      ? units
                      : heroes"
                    :value="card.id"
                    v-bind:key="card.id"
                  >
                    {{ card.name }}
                  </option>
                </select>
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.AMOUNT") }}</p>
                <input v-model.number="trophyObj[milestone][0].amount" />
              </div>
            </template>
            <template v-if="trophyObj[milestone][0].type === 8">
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.AMOUNT") }}</p>
                <input v-model.number="trophyObj[milestone][0].amount" />
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.CARD_TYPE") }}</p>
                <select v-model.number="trophyObj[milestone][0].card_type">
                  <option
                    v-for="option in trophyConstants.CARD_TYPES"
                    :value="option.value"
                    v-bind:key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.OPTION_1") }}</p>
                <select v-model="trophyObj[milestone][0].options[0]">
                  <option
                    v-for="card in trophyObj[milestone][0].card_type === 0
                      ? units
                      : heroes"
                    :value="card.id"
                    v-bind:key="card.id"
                  >
                    {{ card.name }}
                  </option>
                </select>
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.OPTION_2") }}</p>
                <select v-model="trophyObj[milestone][0].options[1]">
                  <option
                    v-for="card in trophyObj[milestone][0].card_type === 0
                      ? units
                      : heroes"
                    :value="card.id"
                    v-bind:key="card.id"
                  >
                    {{ card.name }}
                  </option>
                </select>
              </div>
            </template>
            <template v-if="trophyObj[milestone][0].type === -1">
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.ARENA") }}</p>
                <select v-model.number="trophyObj[milestone][0].arena_type">
                  <option
                    v-for="option in trophyConstants.ARENA_TYPES"
                    :value="option.value"
                    v-bind:key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.ARENA_NAME") }}</p>
                <input v-model="trophyObj[milestone][0].arena_name" />
              </div>
              <div class="subcell subcell-grow">
                <p>{{ $t("TROPHY_VALUES.DESCRIPTION") }}</p>
                <textarea v-model="trophyObj[milestone][0].description" />
              </div>
            </template>
          </div>
          <div class="cell type-cell" v-if="trophyObj[milestone][1]">
            <select
              v-model.number="trophyObj[milestone][1].type"
              @change="() => updateRewardType(milestone, 1)"
            >
              <option
                v-for="type in trophyConstants.TROPHY_REWARD_TYPES"
                v-bind:key="type.value"
                :disabled="![3, 4].includes(type.value)"
                :value="type.value"
              >
                {{ $t("TROPHY_VALUES." + type.text) }}
              </option>
            </select>
            <button
              class="error"
              @click="() => removeRewardFromSecondSlot(milestone)"
            >
              {{ $t("REMOVE") }}
            </button>
          </div>
          <div class="cell reward-cell" v-if="trophyObj[milestone][1]">
            <template v-if="[0, 1].includes(trophyObj[milestone][1].type)">
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.AMOUNT") }}</p>
                <input v-model.number="trophyObj[milestone][1].amount" />
              </div>
            </template>
            <template v-if="trophyObj[milestone][1].type === 2">
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.CHEST") }}</p>
                <select v-model="trophyObj[milestone][1].id">
                  <option
                    v-for="option in chests"
                    :value="option.id"
                    v-bind:key="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.ARENA") }}</p>
                <input v-model.number="trophyObj[milestone][1].arena" />
              </div>
            </template>
            <template v-if="[3, 4].includes(trophyObj[milestone][1].type)">
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.RARITY") }}</p>
                <select v-model.number="trophyObj[milestone][1].rarity">
                  <option
                    v-for="option in trophyConstants.RARITIES"
                    :value="option.value"
                    v-bind:key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.AMOUNT") }}</p>
                <input v-model.number="trophyObj[milestone][1].amount" />
              </div>
            </template>
            <template v-if="trophyObj[milestone][1].type === 8">
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.AMOUNT") }}</p>
                <input v-model.number="trophyObj[milestone][1].amount" />
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.CARD_TYPE") }}</p>
                <select v-model.number="trophyObj[milestone][1].card_type">
                  <option
                    v-for="option in trophyConstants.CARD_TYPES"
                    :value="option.value"
                    v-bind:key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.OPTION_1") }}</p>
                <select v-model="trophyObj[milestone][1].options[0]">
                  <option
                    v-for="card in trophyObj[milestone][1].card_type === 0
                      ? units
                      : heroes"
                    :value="card.id"
                    v-bind:key="card.id"
                  >
                    {{ card.name }}
                  </option>
                </select>
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.OPTION_2") }}</p>
                <select v-model="trophyObj[milestone][1].options[1]">
                  <option
                    v-for="card in trophyObj[milestone][1].card_type === 0
                      ? units
                      : heroes"
                    :value="card.id"
                    v-bind:key="card.id"
                  >
                    {{ card.name }}
                  </option>
                </select>
              </div>
            </template>
            <template v-if="trophyObj[milestone][1].type === -1">
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.ARENA") }}</p>
                <select v-model.number="trophyObj[milestone][1].arena_type">
                  <option
                    v-for="option in trophyConstants.ARENA_TYPES"
                    :value="option.value"
                    v-bind:key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="subcell">
                <p>{{ $t("TROPHY_VALUES.ARENA_NAME") }}</p>
                <input v-model="trophyObj[milestone][1].arena_name" />
              </div>
              <div class="subcell subcell-grow">
                <p>{{ $t("TROPHY_VALUES.DESCRIPTION") }}</p>
                <textarea v-model="trophyObj[milestone][1].description" />
              </div>
            </template>
          </div>
          <div
            class="cell"
            v-if="
              !trophyObj[milestone][1] &&
              [3, 4].includes(trophyObj[milestone][0].type)
            "
          >
            <button
              class="success"
              @click="() => addRewardToSecondSlot(milestone)"
            >
              {{ $t("ADD") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons-section">
      <button class="success" @click="submit">Save</button>
      <button v-if="env !== 'dev'" class="info" @click="() => exportTo('dev')">
        Export to Development
      </button>
      <button
        v-if="env !== 'staging'"
        class="info"
        @click="() => exportTo('staging')"
      >
        Export to Staging
      </button>
      <button
        v-if="env !== 'prod'"
        class="info"
        @click="() => exportTo('prod')"
      >
        Export to Production
      </button>
    </div>
    <ConfirmPopup
      :popup-open="popupOpen"
      :text="popupText"
      @popup-close="popupOpen = false"
      :type="popupType"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onBeforeMount,
  onUpdated,
  onBeforeUnmount,
} from "vue";

import csvUtil from "@/util/csvBuilder"
import { useStore } from "vuex";
import trophyConstants from "../../constants/trophyConstants";
import dispatchMap from "@/constants/dispatchMap";
import { io } from "socket.io-client";
import ConfirmPopup from "../../components/common/ConfirmPopup.vue"
import { getRandomString } from '../../util/randomStringGenerator'
export default {
  name: "Trophy",
  components: {
    ConfirmPopup
  },
  methods: {
    async onFileChanged() {
      let file = this.$refs.file.files[0]
      if (!file) return;

      let reader = new FileReader();
      reader.readAsText(file, 'UTF-8')

      reader.onload =  evt => {
          let text = evt.target.result;
          csvUtil.csvToJson(text).then(result => {
              this.buildTrophyObjectFromImportedFile(result)
          })
      }
      
      reader.onerror = evt => {
          console.error(evt);
      }
    },
  },
  setup() {

    const store = useStore();
    const trophyObj = ref({});
    const currentVersion = ref("");
    const usedPages = ["trophyRoad"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const popupOpen = ref(false);
    const popupText = ref(
      "This page is edited by another user. Please refresh before making changes."
    );
    const popupType = ref("refresh");
    onBeforeMount(() =>
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => store.dispatch(dispatchStr));
      })
    );
    let chestsDisplay = ref([]);
    let unitsDisplay = ref([]);
    let heroesDisplay = ref([]);
    const chests = computed(() => store.getters["chests/getChests"]);
    const units = computed(() => store.getters["units/getUnits"]);
    const heroes = computed(() => store.getters["heroes/getHeroes"]);

    watch(
      () => store.getters["road/getTrophyRoad"],
      (value) => {
        trophyObj.value = JSON.parse(JSON.stringify(value));
        currentVersion.value = trophyObj.value.version
        delete trophyObj.value.version
      },
      { immediate: true }
    );
    watch(
      () => store.getters["units/getUnits"],
      (getterUnits) => {
        unitsDisplay = getterUnits;
      },
      { immediate: true }
    );

    watch(
      () => store.getters["heroes/getHeroes"],
      (getterHeroes) => {
        heroesDisplay = getterHeroes;
      },
      { immediate: true }
    );

    watch(
      () => store.getters["chests/getChests"],
      (getterChests) => {
        chestsDisplay = getterChests;
      },
      { immediate: true }
    );

    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const buildTrophyObjectFromImportedFile = (data) => {
      currentVersion.value = getRandomString(16)
      const obj = {}
      data.result.forEach(row => {
        if (trophyObj.value[row.milestone]) 
          obj[row.milestone] = trophyObj.value[row.milestone]
        else
          obj[row.milestone] = [{type: 3, rarity: 0, amount: 0}]
      })
      trophyObj.value = obj;
    }

    const submit = () => {
      if (currentVersion.value && currentVersion.value !== "")
        trophyObj.value.version = currentVersion.value

      store.dispatch("road/updateTrophyRoad", trophyObj.value).then(() => {
        socket.emit("update", {
          socketId: socket.id,
          itemId: "Trophy Road",
          page: "Trophy Road",
        });
      });
    };
    socket.on("updated", (data) => {
      if (data.socketId != socket.id && data.page === "Trophy Road") {
        popupOpen.value = true;
      }
    });
    const addRewardToSecondSlot = (milestone) => {
      trophyObj.value[milestone].push({
        type: 3,
        rarity: 0,
        amount: 0,
      });
    };

    const removeRewardFromSecondSlot = (milestone) => {
      trophyObj.value[milestone].splice(1, 1);
    };

    const updateRewardType = (milestone, rewardIndex) => {
      const type = trophyObj.value[milestone][rewardIndex].type;
      if (type === 0 || type === 1)
        trophyObj.value[milestone][rewardIndex].amount = 0;
      if (type === 2) {
        trophyObj.value[milestone][rewardIndex].arena = 1;
        trophyObj.value[milestone][rewardIndex].id = "";
      }
      if (type === 3 || type === 4) {
        trophyObj.value[milestone][rewardIndex].selection_type = 0
        trophyObj.value[milestone][rewardIndex].amount = 0;
        trophyObj.value[milestone][rewardIndex].rarity = 0;
        trophyObj.value[milestone][rewardIndex].card_id = "";
      }
      if (type === 8) {
        trophyObj.value[milestone][rewardIndex].amount = 0;
        trophyObj.value[milestone][rewardIndex].card_type = 0;
        trophyObj.value[milestone][rewardIndex].options = [""];
      }
      if (type === -1) {
        trophyObj.value[milestone][rewardIndex].arena_name = "";
        trophyObj.value[milestone][rewardIndex].description = "";
        trophyObj.value[milestone][rewardIndex].arena_type = 0;
      }
    };

    const exportTo = (environment) => {
      store.dispatch("road/exportTrophyRoad", {
        trophyRoad: trophyObj.value,
        environment,
      });
    };

    const appConfig = computed(() => store.getters["auth/getAppConfig"]);
    const env = appConfig.value.appEnv || "dev";

    return {
      trophyObj,
      submit,
      exportTo,
      env,
      trophyConstants,
      chests,
      units,
      heroes,
      chestsDisplay,
      unitsDisplay,
      heroesDisplay,
      addRewardToSecondSlot,
      removeRewardFromSecondSlot,
      updateRewardType,
      popupOpen,
      popupText,
      popupType,
      buildTrophyObjectFromImportedFile
    };
  },
};
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 15px;
}
.content-row {
  padding: 15px;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.content-row.alternate {
  background-color: #f8f9fa;
}

.row-title {
  width: 80%;
  text-align: start;
}

.col {
  border: 1px solid black;
}

.col-title {
  padding: 20px 0;
  font-weight: 900;
  font-size: 1.5rem;
  background-color: #e0e0e0;
}

.category-title {
  padding: 20px 0;
  font-weight: bold;
  font-size: 1.25rem;
  background-color: #f0f0f0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

.header-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: right;
}

.form-container {
  display: flex;
  margin: 25px;
  padding: 10px;
  border: 1px solid black;
}

.sub-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.form-container p {
  width: 60%;
  text-align: start;
  font-weight: bold;
}
.form-container input,
.form-container div,
.form-container select {
  width: 40%;
}

.table {
  width: 100%;
}

.reward-cell,
.cell {
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.header-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 10fr 10fr;
  background-color: lightgrey;
}

.header-cell {
  font-size: 1.3rem;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 8fr 2fr 8fr;
  padding: 15px 0;
  border-bottom: 1px solid black;
}

.table-row.alternate {
  background-color: lightgrey;
}

.type-cell {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.subcell-grow {
  flex-grow: 1;
}

.subcell {
  padding: 0 5px;
  height: 100%;
}

.subcell p {
  font-weight: bold;
  text-decoration: underline;
}
</style>