export default {
    TROPHY_REWARD_TYPES: [
        {
            value: 0,
            text: "GOLD"
        },
        {
            value: 1,
            text: "GEM"
        },
        {
            value: 8,
            text: "PICK_ONE"
        },
        {
            value: 2,
            text: "CHEST"
        },
        {
            value: -1,
            text: "ARENA_UNLOCK"
        },
        {
            value: 3,
            text: "UNIT"
        },
        {
            value: 4,
            text: "HERO"
        },
    ],
    CARD_REWARD_TYPE: [
        {
            value: 0,
            text: "Random"
        },
        {
            value: 1,
            text: "Specific"
        },
    ],
    ARENA_TYPES: [
        {
            value: 0,
            text: 'MAGIC_FOREST',
            minTrophy: 0
        },
        {
            value: 1,
            text: 'PIRATE_BEACH',
            minTrophy: 200
        },
        {
            value: 2,
            text: 'SNOWY_PEAKS',
            minTrophy: 400
        },
        {
            value: 3,
            text: 'LAVA_PIT',
            minTrophy: 700
        },
        {
            value: 4,
            text: 'TOXIC_SWAMP',
            minTrophy: 1000
        },
        {
            value: 5,
            text: 'OCEAN_FLOOR',
            minTrophy: 1300
        },
        {
            value: 6,
            text: 'CANDY_TOWN',
            minTrophy: 1600
        },
        {
            value: 7,
            text: 'CRYSTAL_MINE',
            minTrophy: 2000
        },
        {
            value: 8,
            text: 'ROYAL_CASTLE',
            minTrophy: 2500
        },
        {
            value: 9,
            text: 'GRAVEYARD',
            minTrophy: 3000
        },
        {
            value: 10,
            text: 'CIRCUS',
            minTrophy: 3500
        },
        {
            value: 11,
            text: 'LEAGUE',
            minTrophy: 4000
        },
    ],
    RARITIES: [
        {
            value: 0,
            text: 'COMMON'
        },
        {
            value: 1,
            text: 'RARE'
        },
        {
            value: 2,
            text: 'EPIC'
        },
        {
            value: 3,
            text: 'LEGENDARY'
        },
    ],
    CARD_TYPES: [
        {
            value: 0,
            text: 'UNIT'
        },
        {
            value: 1,
            text: 'HERO'
        }
    ]
}